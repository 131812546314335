import React from 'react'
import ReactDOM from 'react-dom'

// Apps
import {App} from './app/App'


import 'rsuite/dist/rsuite.css'


/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import './index.css'
import {UserProvider} from './app/modules/context/UserProvider'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

import {QueryClientProvider, QueryClient} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {NotificationsProvider} from './app/modules/context/NotificationsProvider'

const queryClient = new QueryClient()

const {PUBLIC_URL} = process.env


//  password: 4eDs4wvNkh88QMuHxmbF
ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        {/*<UserProvider>*/}
            {/*<NotificationsProvider>*/}
                <App basename={PUBLIC_URL} />
            {/*</NotificationsProvider>*/}
        {/*</UserProvider>*/}

        <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
    </QueryClientProvider>
    ,
    document.getElementById('root'),
)
