import {FC, useEffect, useState} from 'react'
import {ChildrenInterface} from '../../../global'
import {initialState, UserContext, UserInterface} from './types'
import SetupAxios from '../../../setup/axios/SetupAxios'
import {API_URL} from '../all-pages'
import {useLocation} from 'react-router-dom'
import axios from 'axios'

const GET_USER = process.env.REACT_APP_GET_USER || 'get_user'

const fetchData_ = async (token: string): Promise<UserInterface> => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}${GET_USER}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
}

const fetchData = async (endpoint: string): Promise<UserInterface> => {
    try {
        const response = await SetupAxios.get<UserInterface>(endpoint);
        console.log("------------------ fetchData");
        console.log(response);
        const data = response.data;

        // Save data to local storage with a timestamp
        localStorage.setItem('_userInterface', JSON.stringify({ data, timestamp: Date.now() }));
        localStorage.setItem('_clientCreationDate', data.created_at);
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};


export const UserProvider: FC<ChildrenInterface> = ({children}) => {

    const [personalInformation, setPersonalInformation] = useState<UserInterface>(initialState)

    const {pathname} = useLocation();
    const token = localStorage.getItem("_token")


    const segments = pathname.split('/token/');
    const newToken = segments[1];
    useEffect(() => {

        if (newToken) {
            fetchData_(newToken)
                .then(r => {
                    console.log("---------------------------  ", r)
                    localStorage.setItem('_userInterface', JSON.stringify({ data: r, timestamp: Date.now() }));
                    localStorage.setItem('_clientCreationDate', r.created_at);
                    localStorage.setItem('_token', newToken);
                    setPersonalInformation(r)
                    window.location.href = "/dashboard"
                })
                .catch((e) => {
                    console.log(e)
                })
        } else if (token) {
            fetchData_(token)
                .then(r => {
                    localStorage.setItem('_userInterface', JSON.stringify({ data: r, timestamp: Date.now() }));
                    localStorage.setItem('_clientCreationDate', r.created_at);
                    localStorage.setItem('_token', token);
                    setPersonalInformation(r)
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }, [newToken, token])

    // useQuery('UserInfos', () => fetchData(GET_USER), {
    //     onSuccess: (newData) => {
    //         setPersonalInformation(newData)
    //     },
    // })

    return (
        <UserContext.Provider value={{personalInformation, setPersonalInformation}}>
            {children}
        </UserContext.Provider>
    )
}

