import {Card, Card1, DateRange, PageHeader, Table, usePeriod} from '../../modules/all-pages'
import {useQuery} from 'react-query'
import {Get} from '../../modules/functions/get/Get'
import {initialStatsClicks, StatsClicks} from './types'
import React, {useState} from 'react'
import {ValueType} from 'rsuite/cjs/DateRangePicker/types'
import {subDays} from 'date-fns'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const STATISTICS_CLICK_NUMBER = process.env.REACT_APP_STATISTICS_CLICK_NUMBER || 'statistics-click-number'

type BrowsersType = "Chrome" | "Edge" | "Firefox" | "Opera";


const ConversionClicks = () => {

    const [data, setData] = useState<StatsClicks>(initialStatsClicks)
    // const [sortKey, setSortKey] = useState<SortKeysForm>('dateHour')
    // const [sortOrder, setSortOrder] = useState<SortOrder>('asc')
    const [rangeValue, setRangeValue] = useState<ValueType>([subDays(new Date(), 29), new Date()])
    const [dateTimeStart, dateTimeEnd] = rangeValue
    const period = usePeriod(dateTimeStart, dateTimeEnd)
    const [loadingStatus, setLoadingStatus] = useState<'idle' | 'pending' | 'fulfilled' | 'rejected'>('idle')

    const browsers: BrowsersType[] = ["Chrome", "Edge", "Firefox", "Opera"];
    const getBrowserIcon = (browser: string): string => {
        if (browsers.includes(browser as BrowsersType)) {
            return `/media/browsers/${browser.toLowerCase()}.png`;
        }
        return `/media/browsers/generic-browser.png`; // Fallback to generic browser icon
    };


    const tableBody = (
        <>
            {data.click_number.nombreClick.map((item, index) => (
                <tr key={index}>
                    <td className="ps-4">{item.dateHour}</td>
                    <td><span className="badge badge-light-primary fs-6 fw-bolder">{item.eventCount}</span></td>
                    <td>{item.sessionSource}</td>
                    <td>{item.pagePath}</td>
                    <td>{item.city}, <span className="fw-bolder text-capitalize">{item.country}</span></td>
                    <td>
                        <div className="symbol symbol-30px me-5">
                            <span className="symbol-label">
                                <img
                                    className="h-50 align-self-center"
                                    src={toAbsoluteUrl(getBrowserIcon(item.browser))}
                                    alt={item.browser}
                                />
                            </span>
                        </div>
                        {item.browser}
                    </td>
                    <td>{item.platformDeviceCategory}</td>
                </tr>
            ))}
        </>
    )

    const tableHead = (
        <>
            <th className="ps-4 fw-bolder text-muted bg-light min-w-150px text-capitalize">Date</th>
            <th className="fw-bolder text-muted bg-light min-w-50px text-capitalize">Clics</th>
            <th className="fw-bolder text-muted bg-light min-w-200px text-capitalize">Source</th>
            <th className="fw-bolder text-muted bg-light min-w-150px text-capitalize">Chemin</th>
            <th className="fw-bolder text-muted bg-light min-w-200px text-capitalize">Pays</th>
            <th className="fw-bolder text-muted bg-light min-w-150px text-capitalize">Navigateur</th>
            <th className="fw-bolder text-muted bg-light min-w-150px text-capitalize">appareil</th>
        </>
    )

    useQuery(['clicks', period],
        () => Get<StatsClicks>({endpoint: STATISTICS_CLICK_NUMBER, period}),
        {
            onSuccess: (data) => {
                setLoadingStatus('fulfilled')
                if (data) setData(data)
            },
        },
    )

    return (
        <>
            <PageHeader icon={'/media/icons/duotune/coding/cod006.svg'}
                        title={'Suivi des clics et conversions'}
                        description={'Accédez à une vue détaillée de vos clics et conversions. Consultez les informations essentielles telles que la date, la source, le chemin, le pays, le navigateur et l’appareil utilisé par vos visiteurs. Ces données vous permettent d’analyser l’engagement des utilisateurs et d’optimiser vos campagnes pour maximiser vos résultats.'}
            />
            <Card id={'clicks'} title={'Conversion Clics'}>
                <div className="row mb-5">
                    <div className="col-12 col-md-4 mb-5 mb-md-0">
                        <Card1 bg={0} countUp={data.total_click_number} description={'total des clicks'} />
                    </div>
                    <div className="col-12 col-md-4 offset-0 offset-md-4 d-flex justify-content-end align-items-end mb-5 mb-md-0">
                        <DateRange rangeValue={rangeValue}
                                   setRangeValue={setRangeValue}
                                   loadingStatus={loadingStatus}
                                   setLoadingStatus={setLoadingStatus}
                                   className="d-flex justify-content-end align-items-center"
                        />
                    </div>
                </div>
                <Table thead={tableHead} tbody={tableBody} />
            </Card>
        </>
    )
}

export {ConversionClicks}