import React, {useEffect, useState} from 'react'
import {Redirect, useParams} from 'react-router-dom'
import {SlugForgot} from './TypesGet'
import {UserInterface} from '../../context/types'
import axios from "axios"
import SetupAxios from '../../../../setup/axios/SetupAxios'
import {API_URL, localStorageValue} from '../../all-pages'
export default function Admin() {

    const [redirect, setRedirect] = useState(false);
    const GET_USER = process.env.REACT_APP_GET_USER;
    const [error, setError] = useState<string | null>(null); // State to handle errors


    // const Logout = async () => {
    //     try {
    //         await axios({
    //             method: 'GET',
    //             url: `${API_URL}logout?token=${localStorageValue}`,
    //         });
    //     } catch (error) {
    //     }
    // };
    //
    // const Login = async () => {
    //     if (GET_USER) {
    //         try {
    //             const response = await axios({
    //                 method: 'GET',
    //                 url: `${API_URL}${GET_USER}`,
    //                 headers: {
    //                     'Authorization': `Bearer ${localStorageValueSlug}`,
    //                 },
    //             });
    //             return response.data;
    //         } catch (error) {
    //             setError('Error during login');
    //             console.error(error);
    //         }
    //     }
    // };

    // if (redirect) {
    //     return <Redirect to='/dashboard' />
    // }
    //
    // return <Redirect to='/login' />

    return <Redirect to='/dashboard' />
    //
    // return <h1>HEllo world!</h1>
}