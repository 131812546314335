import React, {Suspense, lazy} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {FallbackView} from '../../_metronic/partials';
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper';
import {Products} from "../pages/products/Products";
import {PrivacyPolicy} from "../pages/privacy-policy/PrivacyPolicy";
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {CreateAccountCT} from "../modules/products/call-traking/CreateAccountCT";
import {CreateAccountS} from "../modules/products/statistiques/CreateAccountS";
import {CreateAccountM} from "../modules/products/mailing/CreateAccountM";
import {CreateAccountWF} from "../modules/products/web-formulaires/CreateAccountWF";
import {Report} from '../pages/report/Report'
import {Horizontal} from '../modules/wizards/components/Horizontal'
import {ConversionClicks} from '../pages/clicks/ConversionClicks'

export function PrivateRoutes() {

    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
    const CallTracking = lazy(() => import('../modules/products/call-traking/CallTracking'));
    const Mailing = lazy(() => import('../modules/products/mailing/Mailing'));
    const WebForms = lazy(() => import('../modules/products/web-formulaires/WebFormulaires'));
    const E_Reputation = lazy(() => import('../modules/products/e-reputation/E_Reputation'));
    const Statistics = lazy(() => import('../modules/products/statistiques/Statistiques'));
    const Contacts = lazy(() => import('../pages/contacts/Contacts'));

    return (

    <Suspense fallback={<FallbackView />}>
      <Switch>
          <Route path='/dashboard' component={DashboardWrapper} />
          {/*<Route path='/dashboard' component={DashboardWrapperOld} />*/}
          <Route path='/pages/products' component={Products} />
          <Route path='/pages/privacy-policy' component={PrivacyPolicy} />
          <Route path='/pages/profile' component={ProfilePage} />
          <Route path='/call-tracking' component={CallTracking} />
          <Route path='/mailing' component={Mailing} />
          <Route path='/web-formulaires' component={WebForms} />
          <Route path='/e-reputation' component={E_Reputation} />
          <Route path='/statistiques' component={Statistics} />
          <Route path='/contacts' component={Contacts} />
          <Route path='/error' component={ErrorsPage} />
          <Route path='/creer-compte-calltracking' component={CreateAccountCT} />
          <Route path='/creer-compte-webformulaires' component={CreateAccountWF} />
          <Route path='/creer-compte-mailing' component={CreateAccountM} />
          <Route path='/creer-compte-statistiques' component={CreateAccountS} />
          <Route path='/rapport' component={Report} />
          <Route path='/horizontal' component={Horizontal} />
          <Route path='/clicks' component={ConversionClicks} />

          <Redirect exact from='/' to='/dashboard' />
          <Redirect to='/error/' />
      </Switch>
    </Suspense>
  )
}
