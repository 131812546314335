import {useMemo, useState} from 'react'

type SearchTypes<T extends Record<string, unknown>> = {
    data: T[];
};

function useSearchedData<T extends Record<string, unknown>>({data}: SearchTypes<T>) {

    const [searchQuery, setSearchQuery] = useState<string>('')

    const searchedData = useMemo(() => {
        const lowerQuery = searchQuery.toLowerCase()
        return data.filter(item => {
            return Object.values(item)
                .join(' ')
                .toLowerCase()
                .includes(lowerQuery)
        })

    }, [searchQuery, data])

    return {searchedData, searchQuery, setSearchQuery}
}

export {useSearchedData}