import React, {createContext, useContext, useState, useEffect} from 'react'
import {DefaultLayoutConfig} from './DefaultLayoutConfig'
import {
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './LayoutSetup'
import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
} from './LayoutModels'
import {ChildrenInterface} from '../../../global'
import {useLocation} from 'react-router-dom'
import {UserInterface} from '../../../app/modules/context/types'
import axios from 'axios'
import {API_URL} from '../../../app/modules/all-pages'

export interface LayoutContextModel {
  config: ILayout
  classes: ILayoutCSSClasses
  attributes: ILayoutHTMLAttributes
  cssVariables: ILayoutCSSVariables
  setLayout: (config: LayoutSetup) => void
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultLayoutConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => {},
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

const GET_USER = process.env.REACT_APP_GET_USER || 'get_user'


const loginWithToken = async (token: string): Promise<UserInterface> => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${API_URL}${GET_USER}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};

const logoutWithToken = async (token: string): Promise<void> => {
  try {

    await axios({
      method: 'GET',
      url: `${API_URL}logout?token=${token}`,
    });
  } catch (error) {
    console.error('Error during logout:', error);
  }
};

const LayoutProvider: React.FC<ChildrenInterface> = ({children}) => {

  const [config, setConfig] = useState(LayoutSetup.config)
  const [classes, setClasses] = useState(LayoutSetup.classes)
  const [attributes, setAttributes] = useState(LayoutSetup.attributes)
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)
  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    LayoutSetup.updatePartialConfig(_themeConfig)
    setConfig(Object.assign({}, LayoutSetup.config))
    setClasses(LayoutSetup.classes)
    setAttributes(LayoutSetup.attributes)
    setCSSVariables(LayoutSetup.cssVariables)
    setTimeout(() => {
      disableSplashScreen()
    }, 500)
  }
  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    setLayout,
  }

  // const {} = useUser()


  useEffect(() => {
    disableSplashScreen()
  }, [])

  const {pathname} = useLocation();
  const segments = pathname.split('/token/');
  const newToken = segments[1];
  const oldToken = localStorage.getItem('_token');

  // useEffect(() => {
  //   const handleTokenChange = async () => {
  //     if (newToken) {
  //       // Attempt logout and login flow
  //       if (oldToken) {
  //         await logoutWithToken(oldToken)
  //             .catch((error) => {
  //               console.error('Logout failed:', error)
  //             })
  //             .finally(() => {
  //               localStorage.clear()
  //             })
  //       }
  //
  //       const userData = await loginWithToken(newToken)
  //       SetupAxios.defaults.headers.common['Authorization'] = newToken
  //       localStorage.setItem('_token', newToken)
  //       localStorage.setItem('_userInterface', JSON.stringify({data: userData, timestamp: Date.now()}))
  //       localStorage.setItem('_clientCreationDate', userData.created_at)
  //
  //     } else {
  //       // Fallback: Fetch data
  //       const userData = await loginWithToken(newToken)
  //       SetupAxios.defaults.headers.common['Authorization'] = newToken
  //       localStorage.setItem('_token', newToken)
  //       localStorage.setItem('_userInterface', JSON.stringify({data: userData, timestamp: Date.now()}))
  //       localStorage.setItem('_clientCreationDate', userData.created_at)
  //     }
  //   }
  //
  //   handleTokenChange()
  //       .then((r) => console.log("-------------- R handleTokenChange", r))
  //       .catch((e) => console.log("-------------- E handleTokenChange", e))
  // }, [newToken, oldToken]);

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export {LayoutContext, LayoutProvider}

export function useLayout() {
  return useContext(LayoutContext)
}
