import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../helpers'

interface SearchInterface {
  setSearchInput: Dispatch<SetStateAction<string>>;
  showK?: boolean;
}

const kbd = {
  backgroundColor: '#b7c6cf',
  color: '#fff',
  borderRadius: 3,
  display: 'inline-flex',
  letterSpacing: '-.003rem',
  padding: '1px 4px',
}

const Search: FC<SearchInterface> = ({setSearchInput, showK = true}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [showKbd, setShowKbd] = useState<boolean>(true)

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!showK) return
      if (e.ctrlKey && e.key === 'k' && inputRef.current) {
        e.preventDefault()
        inputRef.current.focus()
        setShowKbd(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [showK])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchInput(value)

    if (value.length > 0) {
      setShowKbd(false)
    }
  }

  const handleBlur = () => {
    if (inputRef.current?.value.length === 0) {
      setShowKbd(true)
    }
  }

  return (
      <form className="w-100 position-relative" autoComplete="off">
        <div className="position-absolute top-50 ms-5 translate-middle-y">
          <KTSVG path="/media/icons/duotune/general/gen021.svg"
                 className="svg-icon-2 svg-icon-lg-1 svg-icon-gray-500"
          />
        </div>
        <input type="text"
               className="form-control form-control-solid form-control-sm px-15"
               id="search"
               name="search"
               placeholder="Rechercher..."
               ref={inputRef}
               onChange={handleInputChange}
               onBlur={handleBlur}
               onFocus={() => setShowKbd(false)}
        />
        {
            showK && (
                <div
                    style={{position: 'absolute', right: 15}}
                    className={`position-absolute top-50 translate-middle-y ${!showKbd && 'd-none'}`}
                >
                  <kbd style={kbd}>Ctrl</kbd>
                  <span className="mx-1 text-gray-500">+</span>
                  <kbd style={kbd}>K</kbd>
                </div>
            )
        }
      </form>
  )
}

export {Search}
