import CountUp from 'react-countup'
import React, {FC} from 'react'
import {useUser} from '../../../context/types'

interface Card1Interface {
    bg: 0 | 1
    countUp: number
    description: string
}

const Card1: FC<Card1Interface> = ({bg, countUp, description}) => {

    // const {personalInformation: {workspace}} = useUser()
    // const primary = workspace?.color.main
    // const secondary = workspace?.color.second

    const backgroundColor = bg ? "#0094f3bf" : "#e1006dbf"

    return (
        <div className="card card-flush" style={{backgroundColor}}>
            <div className="card-body d-flex flex-column p-5">
                                        <span className="fs-2 fw-bold text-white me-2 lh-1 ls-n2">
                                            <CountUp end={countUp} duration={1.5} />
                                        </span>
                <span className="text-white pt-1 fw-semibold fs-7">{description}</span>
            </div>
        </div>
    )
}

export {Card1}