import {KTSVG} from '../../../../_metronic/helpers'
import React, {FC} from 'react'
import {useUser} from '../../context/types'

type Props = {
    icon: string
    title: string
    description: string
}


const PageHeader: FC<Props> = ({icon, description, title}) => {


    return (
        <div className="card mb-6 mb-xl-9">
            <div className="card-body">
                <div className="d-flex flex-wrap flex-sm-nowrap">
                    <div
                        className="d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px me-7 mb-4">

                        <KTSVG path={icon}
                               className={`svg-icon svg-icon-4hx svg-icon-primary`}
                        />
                    </div>
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center mb-1">
                            <span className="text-gray-800 fs-2 fw-bolder me-3">{title}</span>
                        </div>
                        <div className="d-flex flex-wrap fw-bold mb-4 fs-6 text-gray-500">{description}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {PageHeader}