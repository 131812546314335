import React, {FC} from 'react'
import {Route, Switch} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {PublicRoutes} from './PublicRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {ForgotPassword} from '../pages/auth/forgot-password/ForgotPassword'
import Admin from '../modules/functions/get/Admin'
import {ResetPassword} from '../pages/auth/forgot-password/ResetPassword'

const Routes: FC = () => {

    return (
        <>
            <Switch>

                <Route path="/token/:slug" component={Admin} />

                <Route path='/login'>
                    <PublicRoutes/>
                </Route>

                <Route path='/error' component={ErrorsPage}/>

                <Route path='/mot-de-passe-oublie' component={ForgotPassword}/>
                <Route path='/mot-de-passe-reinitialiser/:slug/:email' component={ResetPassword}/>

                <MasterLayout>
                    <PrivateRoutes/>
                </MasterLayout>

            </Switch>
      </>
  )

}

export {Routes}
