import {Dispatch, SetStateAction} from 'react'
import {
    InterfaceTemplateCreate,
    InterfaceTemplateDefault,
} from '../../../../products/mailing/components/campagnes/settings/contents/components/template/TypeTemplates'
import SetupAxios from '../../../../../../setup/axios/SetupAxios'

interface Props {
    setDataTemplateGallery?: Dispatch<SetStateAction<InterfaceTemplateDefault[]>>;
    setDataTemplateCreate?: Dispatch<SetStateAction<InterfaceTemplateCreate[]>>;
}

export default async function AllTemplates({setDataTemplateGallery, setDataTemplateCreate}: Props) {

    const ALL_TEMPLATES = process.env.REACT_APP_MAILING_ALL_TEMPLATES || 'v2/templates'

    await SetupAxios.get<any>(ALL_TEMPLATES)
        .then((response) => {
            const {templatesDefault, templatesCreate} = response.data
            // console.log("------------------------------- AllTemplates", response.data)
            if (setDataTemplateGallery) setDataTemplateGallery(templatesDefault)
            if (setDataTemplateCreate) setDataTemplateCreate(templatesCreate)
        }).catch((error: any) => {
            console.log(error.response)
        })
    // axios({
    //     method: "GET",
    //     url: `${API_URL}${ALL_TEMPLATES}`,
    //     headers: {
    //         'Authorization': `Bearer ${localStorageValue}`,
    //     },
    // })
    //     .then((response) => {
    //
    //         const {templatesDefault, templatesCreate} = response.data;
    //
    //         if (setDataTemplateGallery) setDataTemplateGallery(templatesDefault)
    //         if (setDataTemplateCreate) setDataTemplateCreate(templatesCreate)
    //
    //     })
    //     .catch((error) => {
    //         console.log(error)
    //     })

}